export function emphasize(wordsToMatch, string, htmlTag='u') {
  let pattern = new RegExp(`(${wordsToMatch.join('|')})`)
  return string.replace(pattern, `<${htmlTag}>$1</${htmlTag}>`)
}

export function scrollToTop() {
  window.scrollTo(0,0);
}

export const sample = (arr) => arr[Math.floor(Math.random() * arr.length)];
