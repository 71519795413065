export const state = () => ({
  token: null,
  fetched: false
});

export const mutations = {
  setState(state, payload) {
    state[payload.key] = payload.data;
  },
  setFetched(state) {
    state.fetched = true;
  }
};

export const actions = {
  async fetch(context, shortToken) {
    if (context.state.fetched) return;

    const response = await this.$axios.get(`reviews/short_token/${shortToken}`);
    context.commit("setState", {
      key: "token",
      data: response.data.token
    });
    context.commit("setFetched");
  }
};

export const getters = {
  token: state => state.token,
  fetched: state => state.fetched
};
