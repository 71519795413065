export const setSurveyFont = fontObj => {
  try {
    if (!fontObj) return;
    document.head.innerHTML += `<link href="${fontObj.url}" rel="stylesheet">`;
    const wrapper = document.querySelector(".v-application--wrap");
    wrapper.setAttribute("style", `font-family: ${fontObj.family}`);
  } catch {
    return false;
  }
};

export const setFontColor = color => {
  try {
    if (!fontObj) return;
    const wrapper = document.querySelector(".v-application--wrap");
    wrapper.style.color = color;
  } catch {
    return false;
  }
};

export const setBackgroundImage = image => {
  try {
    if (!image) return;
    const wrapper = document.querySelector("#review");
    wrapper.style.backgroundImage = `url(${require("@/assets/backgrounds/" + image + ".jpg")})`;
  } catch (e) {
    return false;
  }
};
