import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a8f184c8 = () => interopDefault(import('../pages/claim.vue' /* webpackChunkName: "pages/claim" */))
const _06b9177c = () => interopDefault(import('../pages/finished.vue' /* webpackChunkName: "pages/finished" */))
const _78bfdee8 = () => interopDefault(import('../pages/submit.vue' /* webpackChunkName: "pages/submit" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7a04e007 = () => interopDefault(import('../pages/_short_token.vue' /* webpackChunkName: "pages/_short_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/claim",
    component: _a8f184c8,
    name: "claim"
  }, {
    path: "/finished",
    component: _06b9177c,
    name: "finished"
  }, {
    path: "/submit",
    component: _78bfdee8,
    name: "submit"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:short_token",
    component: _7a04e007,
    name: "short_token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
