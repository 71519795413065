import locale9ec66352 from '../../locales/en.json'
import localee31a61d6 from '../../locales/it.json'
import locale275327d8 from '../../locales/fr.json'
import locale8db5ed1c from '../../locales/es.json'
import locale73254525 from '../../locales/id.json'
import locale2b5e828d from '../../locales/vi.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.json"},{"code":"it","iso":"it-IT","file":"it.json"},{"code":"fr","iso":"fr-FR","file":"fr.json"},{"code":"es","iso":"es-ES","file":"es.json"},{"code":"id","iso":"id-ID","file":"id.json"},{"code":"vi","iso":"vi-VI","file":"vi.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.json"},{"code":"it","iso":"it-IT","file":"it.json"},{"code":"fr","iso":"fr-FR","file":"fr.json"},{"code":"es","iso":"es-ES","file":"es.json"},{"code":"id","iso":"id-ID","file":"id.json"},{"code":"vi","iso":"vi-VI","file":"vi.json"}],
  localeCodes: ["en","it","fr","es","id","vi"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "o",
  17: "c",
  18: "a",
  19: "l",
  20: "e",
  21: "s",
  22: "/",
  23: "e",
  24: "n",
  25: ".",
  26: "j",
  27: "s",
  28: "o",
  29: "n",
  30: "\"",
  31: ",",
  32: "\"",
  33: "i",
  34: "t",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ":",
  42: "\"",
  43: ".",
  44: ".",
  45: "/",
  46: "l",
  47: "o",
  48: "c",
  49: "a",
  50: "l",
  51: "e",
  52: "s",
  53: "/",
  54: "i",
  55: "t",
  56: ".",
  57: "j",
  58: "s",
  59: "o",
  60: "n",
  61: "\"",
  62: ",",
  63: "\"",
  64: "f",
  65: "r",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ":",
  73: "\"",
  74: ".",
  75: ".",
  76: "/",
  77: "l",
  78: "o",
  79: "c",
  80: "a",
  81: "l",
  82: "e",
  83: "s",
  84: "/",
  85: "f",
  86: "r",
  87: ".",
  88: "j",
  89: "s",
  90: "o",
  91: "n",
  92: "\"",
  93: ",",
  94: "\"",
  95: "e",
  96: "s",
  97: ".",
  98: "j",
  99: "s",
  100: "o",
  101: "n",
  102: "\"",
  103: ":",
  104: "\"",
  105: ".",
  106: ".",
  107: "/",
  108: "l",
  109: "o",
  110: "c",
  111: "a",
  112: "l",
  113: "e",
  114: "s",
  115: "/",
  116: "e",
  117: "s",
  118: ".",
  119: "j",
  120: "s",
  121: "o",
  122: "n",
  123: "\"",
  124: ",",
  125: "\"",
  126: "i",
  127: "d",
  128: ".",
  129: "j",
  130: "s",
  131: "o",
  132: "n",
  133: "\"",
  134: ":",
  135: "\"",
  136: ".",
  137: ".",
  138: "/",
  139: "l",
  140: "o",
  141: "c",
  142: "a",
  143: "l",
  144: "e",
  145: "s",
  146: "/",
  147: "i",
  148: "d",
  149: ".",
  150: "j",
  151: "s",
  152: "o",
  153: "n",
  154: "\"",
  155: ",",
  156: "\"",
  157: "v",
  158: "i",
  159: ".",
  160: "j",
  161: "s",
  162: "o",
  163: "n",
  164: "\"",
  165: ":",
  166: "\"",
  167: ".",
  168: ".",
  169: "/",
  170: "l",
  171: "o",
  172: "c",
  173: "a",
  174: "l",
  175: "e",
  176: "s",
  177: "/",
  178: "v",
  179: "i",
  180: ".",
  181: "j",
  182: "s",
  183: "o",
  184: "n",
  185: "\"",
  186: "}",
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'it.json': () => Promise.resolve(localee31a61d6),
  'fr.json': () => Promise.resolve(locale275327d8),
  'es.json': () => Promise.resolve(locale8db5ed1c),
  'id.json': () => Promise.resolve(locale73254525),
  'vi.json': () => Promise.resolve(locale2b5e828d),
}
