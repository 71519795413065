export const state = () => ({
  completionId: undefined
});

export const mutations = {
  setId(state, id) {
    state.completionId = id;
  }
};

export const getters = {
  id(state) {
    return state.completionId;
  }
};

export const actions = {
  update({ rootState, state }, survey_completion) {
    if (!state.completionId) {
      return;
    }
    this.$axios.put(`survey_completions/${state.completionId}`, { survey_completion });
  }
};
