<template>
  <div id="footer" app :class="shouldDisplayLogo ? 'justify-space-between with-logo' : 'justify-end'">
    <div v-if="hidePicture"></div>
    <img
      v-else-if="shouldDisplayLogo && !hidePicture"
      :src="venueLogo"
      alt="venue logo"
      class="venue-logo"
      @error="imageLoadError"
    />
    <div v-if="style.showTvLogo" class="powered-by">
      <p class="mb-0 mr-1">By</p>
      <a target="_blank" href="https://www.tablevibe.co">
        <img class="logo" :src="require('~/assets/imgs/logo.png')" width="100" />
      </a>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "Footer",
    data() {
      return {
        hidePicture: false
      };
    },
    computed: {
      ...mapGetters({
        venueLogo: "venue/logo",
        style: "design/style"
      }),
      shouldDisplayLogo() {
        if (!this.style || !this.venueLogo) return false;
        return this.style.showVenueLogo;
      }
    },
    methods: {
      imageLoadError() {
        this.hidePicture = true;
      }
    }
  };
</script>

<style lang="scss">
  #footer {
    z-index: 3;
    display: flex;
    height: 60px;
    padding: 0 16px;
    background-color: white !important;
    align-items: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    .powered-by {
      max-width: 40%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      p.mb-0 {
        font-size: 14px;
        line-height: 19px;
        color: #3d4753;
      }
    }
  }
  #footer.with-logo {
    .powered-by {
      font-size: 0.7em;
    }
    .venue-logo {
      max-width: 60px;
      max-height: 48px;
      object-fit: contain;
    }
  }

  @media only screen and (max-width: 600px) {
    #footer {
      height: 60px;
      padding: 8px 16px;
      .powered-by {
        max-width: none;
        font-size: 8px;
        flex-direction: row;
        img {
          width: 90px;
          margin-left: 6px;
        }
        p {
          position: relative;
          bottom: 1px;
        }
      }
    }
  }
</style>
