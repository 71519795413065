// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://api.fontshare.com/css?f[]=general-sans@400,500,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{min-height:100vh;min-height:-webkit-fill-available}html{height:-webkit-fill-available}.h-100{height:100%}.w-100{width:100%}.w-50{width:50%}.h-50{height:50%}.mh-100{min-height:100%}.p-absolute{position:absolute}.p-relative{position:relative}.page-height{height:calc(100% - 80px);-ms-overflow-style:none;scrollbar-width:none}.page-height::-webkit-scrollbar{display:none}@media only screen and (max-width:400px){.page-height{height:calc(100% - 60px)}}.v-application{background:none!important;font-family:\"General Sans\",sans-serif!important;font-style:normal;font-weight:400}.v-application--wrap{min-height:0!important;min-height:100vh!important}.v-progress-linear--absolute{bottom:80px;position:fixed!important;width:100vw}@media only screen and (max-width:400px){.v-progress-linear--absolute{bottom:60px!important}}.row{margin-left:0!important;margin-right:0!important}.overflow-y-scroll{overflow-y:scroll}textarea{max-height:160px;min-height:160px}.v-input__slot{box-shadow:none!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
