export const state = () => ({
  style: {}
});

export const mutations = {
  setState(state, payload) {
    state[payload.key] = payload.data;
  },
  setStyle(state, payload) {
    state.style = payload;
  }
};

export const getters = {
  style: state => {
    return state.style;
  }
};
