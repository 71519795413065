export const state = () => ({
  answers: [],
  token: String,
  googleShared: Boolean,
  review: {},
});

export const mutations = {
  addAnswer(state, answer) {
    state.answers.push(answer);
  },
  setReviewerAndTerms(state, data) {
    state.review = data;
  },
  set(state, payload) {
    state[payload.key] = payload.data;
  },
};

export const getters = {
  show: (state) => {
    return state.review;
  },
  token: (state) => {
    return state.token;
  },
  googleShared: (state) => {
    return state.googleShared;
  },
  allAnswers: (state) => {
    return state.answers;
  },
};

export const actions = {
  resetAnswers({ commit }) {
    commit("set", { key: "answers", data: [] });
  },
  async setGoogleShared({ getters, commit }) {
    const config = {
      headers: {
        "Review-Token": getters.token,
      },
    };

    let response = await this.$axios.patch("google_shares", {}, config);
    if (response.status == 200) {
      commit("set", { key: "googleShared", data: true });
    }
  },
  async setSocialAction({ getters }) {
    const config = {
      headers: {
        "Review-Token": getters.token,
      },
    };
    await this.$axios.patch("social_links", {}, config);
  },
};
