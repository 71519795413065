// Follow up quesitons
import { capitalizeQuestion } from "@/helpers/text";
import { standardCategories } from "@/constants/standard_questions";
export default [
  {
    id: "love",
    kind: "follow_up",
    attribute: "content",
    goAfter: "category",
    skip: false,
    follow_up_name: "compliment",
    shouldSkip(getters) {
      return !getters.loveCategory;
    },
    generate(getters) {
      let content;
      this.question_name = getters.loveCategory;

      if (getters.language === "en") {
        content = `${getters.loveCategoryText}
        ${getters.questionByName(getters.loveCategory).content}?`;
      } else {
        content = `${getters.questionByName(getters.loveCategory).content}: ${getters.loveCategoryText}?`;
      }
      this.content = standardCategories.includes(getters.loveCategory) ? capitalizeQuestion(content) : content;
    }
  },
  {
    id: "improve",
    kind: "follow_up",
    attribute: "content",
    goAfter: "category",
    follow_up_name: "suggestion",
    skip: false,
    shouldSkip(getters) {
      return !getters.improveCategory;
    },
    generate(getters) {
      let content;
      this.question_name = getters.improveCategory;
      if (getters.language === "en") {
        content = `${getters.improveCategoryText} ${getters.questionByName(getters.improveCategory).content}?`;
      } else {
        content = `${getters.questionByName(getters.improveCategory).content}: ${getters.improveCategoryText}?`;
      }

      this.content = standardCategories.includes(getters.improveCategory) ? capitalizeQuestion(content) : content;
    }
  },
  {
    kind: "follow_up",
    attribute: "rating",
    goAfter: "employee",
    skip: false,
    question_name: "employee",
    follow_up_name: "employeee rating",
    shouldSkip(getters) {
      return false;
    },
    generate(getters) {
      this.content = getters.serviceRateText;
      this.id = getters.questions.find(q => q.question_name == "employee").id;
    }
  }
];
