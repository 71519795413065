export const state = () => ({
  reward: {
    name: "",
    experience_type: "",
    valid_from: undefined,
    valid_to: undefined,
    conditions: []
  }
});

export const mutations = {
  set(state, reward) {
    state.reward = reward;
  },
  setExperienceType(state, type) {
    state.reward = { ...state.reward, experience_type: type };
  }
};

export const getters = {
  name: state => state.reward.name,
  experienceType: state => state.reward.experience_type,
  conditions: state => state.reward.conditions,
  startDate: state => state.reward.valid_from,
  endDate: state => state.reward.valid_to
};
