export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeQuestion = string => {
  const text = capitalize(string);
  try {
    if (!text) return;
    return text.replace(/([!?.]\s*)([a-z])/g, function(m, $1, $2) {
      return $1 + $2.toUpperCase();
    });
  } catch {
    return text;
  }
};
