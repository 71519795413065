export const addParamsToManifestStartURL = async () => {
  const manifest = document.querySelector('[rel="manifest"]')
  if (!manifest) return;

  const res = await fetch(manifest.href);
  const json = await res.json();
  json.start_url = window.location.href

  json.icons = json.icons.map((iconObject => {
    iconObject.src = `${window.location.origin}${iconObject.src}`
    return iconObject
  }))

  const blob = new Blob([JSON.stringify(json)], {type: 'application/json'});
  const manifestURL = await URL.createObjectURL(blob);
  manifest.setAttribute('href', manifestURL);
}
