export const state = () => ({
  venue: {
    name: "",
    logo: "",
  },
  token: "",
  fetched: false,
});

export const mutations = {
  setState(state, payload) {
    state[payload.key] = payload.data;
  },
  setFetched(state) {
    state.fetched = true;
  },
};

export const actions = {
  async fetch(context) {
    if (context.state.fetched) return;
    const config = {
      headers: {
        "VenueExperience-Token": context.state.token,
        Demo: context.rootState.isDemo,
      },
    };
    let response = await this.$axios.get("reviews/new", config);
    context.commit("setState", {
      key: "venue",
      data: { ...response.data.venue, verifyCustomer: response.data.verify_customer },
    });
    context.commit("language/set", response.data.lang, { root: true });
    context.dispatch("survey/surveyData", response.data.survey_data, { root: true });
    context.commit("reward/set", response.data.reward, { root: true });
    context.commit("design/setStyle", response.data.style, { root: true });
    context.commit("completion/setId", response.data.survey_completion_id, { root: true });
    context.commit("setFetched");
  },
};

export const getters = {
  token: (state) => {
    return state.token;
  },
  verifyCustomer: (state) => {
    return state.venue.verifyCustomer;
  },
  id: (state) => {
    return state.venue.id;
  },
  fetched: (state) => {
    return state.fetched;
  },
  name: (state) => {
    return state.venue.name;
  },
  show: (state) => {
    return state.venue;
  },
  logo: (state) => {
    return state.venue.photo;
  },
  googleId: (state) => {
    return state.venue.place_id;
  },
  shareSocial: (state) => {
    return state.venue.review_social_link;
  },
  InstagramUrl: (state) => {
    return state.venue.instagram_url;
  },
  facebookUrl: (state) => {
    return state.venue.facebook_url;
  },
  youtubeVideoId: (state) => {
    return state.venue.youtube_video_id;
  },
  venue: (state) => {
    return state.venue;
  },
  enableSms: (state) => {
    return state.venue.enable_sms;
  },
  accountId: (state) => {
    return state.venue.account_id;
  },
};
