<template>
  <v-app id="app" class="h-100">
    <nuxt />

    <v-snackbar v-model="snackbar">
      <v-btn color="pink" text @click="installApp"> Download App </v-btn>
    </v-snackbar>
    <Footer />
  </v-app>
</template>

<script>
  import Footer from "@/components/shared/Footer.vue";
  import { mapGetters } from "vuex";
  import { addParamsToManifestStartURL } from "@/constants/manifest.js";
  import { setSurveyFont, setFontColor, setBackgroundImage } from "@/helpers/dom";
  export default {
    components: {
      Footer,
    },
    data() {
      return {
        snackbar: false,
        deferredPrompt: null,
        ready: false,
      };
    },
    computed: {
      ...mapGetters({
        style: "design/style",
        language: "language/language",
      }),
    },
    methods: {
      SWRegistration() {
        if ("serviceWorker" in navigator) {
          navigator.serviceWorker.register("/sw.js").then(function () {
            console.log("Service Worker Registered");
          });
        }
      },
      installApp() {
        this.snackbar = false;
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the A2HS prompt");
          } else {
            console.log("User dismissed the A2HS prompt");
          }
          this.deferredPrompt = null;
        });
      },
    },
    watch: {
      style(value) {
        if (!value) return;
        setSurveyFont(value.font);
        setFontColor(value.textColor);
        setBackgroundImage(value.backgroundImage);
      },
      language(value) {
        if (!value) return;
        this.$i18n.setLocale(value);
        this.$store.commit("survey/setLanguage", value);
        this.$store.commit("language/loaded");
      },
      immediate: true,
    },

    mounted() {
      addParamsToManifestStartURL();
      //this.SWRegistration();
    },
    created() {
      if (process.browser) {
        window.addEventListener("beforeinstallprompt", (e) => {
          if (this.$device.isTablet) {
            this.deferredPrompt = e;
            this.snackbar = true;
          } else {
            e.preventDefault();
          }
        });
      }
    },
  };
</script>

<style lang="scss">
  html {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "General Sans", "Helvetica Neue",
      Arial, sans-serif;
    font-size: 16px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
  }
  .button-d-blue.v-btn.theme--light {
    min-width: 200px !important;
    background-color: #ff5722;
    color: white !important;
    font-weight: bold;
  }

  .button--green {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #3b8070;
    color: #3b8070;
    text-decoration: none;
    padding: 10px 30px;
  }

  .button--green:hover {
    color: #fff;
    background-color: #3b8070;
  }

  .button--grey {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #35495e;
    color: #35495e;
    text-decoration: none;
    padding: 10px 30px;
    margin-left: 15px;
  }

  .button--grey:hover {
    color: #fff;
    background-color: #35495e;
  }
</style>
