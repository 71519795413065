export const state = () => ({
  language: undefined,
  set: false
});

export const mutations = {
  set(state, language) {
    state.language = language;
  },
  loaded(state) {
    state.set = true;
  }
};

export const getters = {
  language: state => state.language,
  languageLoaded: state => state.set
};
