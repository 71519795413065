import { sample } from "../modules/utilities";
import followUpQuestions from "../constants/follow_up_questions";

export const state = () => ({
  categories: [],
  questions: [],
  answerOptions: {},
  answers: {},
  previousQuestions: [],
  nextQuestions: [],
  disabledFollowUp: [],
  improveCategory: undefined,
  improveCategoryText: undefined,
  loveCategory: undefined,
  loveCategoryText: undefined,
  serviceRateText: undefined,
  currentStep: 0,
  initialQuestions: 0,
  language: "en",
  shareSocialOnReload: undefined
});

export const mutations = {
  setState(state, { key, data }) {
    state[key] = data;
  },
  setCurrentStep(state) {
    state.currentStep = 1;
  },
  setInitialQuestions(state, data) {
    state.initialQuestions = data;
  },
  nextQuestion(state) {
    if (state.nextQuestions.length === 1) {
      return this.app.router.push("/submit");
    }
    state.previousQuestions.push(state.nextQuestions.shift());
  },
  previousQuestion(state) {
    if (state.previousQuestions.length == 0) {
      return;
    }

    state.nextQuestions.unshift(state.previousQuestions.pop());
    while (state.nextQuestions[0].skip) {
      state.nextQuestions.unshift(state.previousQuestions.pop());
    }
  },
  setAnswer(state, { answer, questionName }) {
    const newAnswers = { ...state.answers };
    newAnswers[questionName] = answer;
    state.answers = newAnswers;
  },
  setFollowUpCategory(state, { key, data }) {
    // Reset answer content if category has changed
    if (state[key] && state[key] != data) {
      state.answers[state[key]].content = null;
    }

    state[key] = data;
  },
  buildFollowUpQuestion(state, { question, getters }) {
    if ((question.skip = question.shouldSkip(getters))) {
      return;
    }

    question.generate(getters);
    question.built = true;

    const index = state.nextQuestions.indexOf(question);
    const newNextQuestions = [...state.nextQuestions];
    newNextQuestions.splice(index, 1, question);
    state.nextQuestions = newNextQuestions;
  },
  setImproveCategoryText(state, content) {
    state.improveCategoryText = content;
  },
  setLoveCategoryText(state, content) {
    state.loveCategoryText = content;
  },
  setLanguage(state, content) {
    state.language = content;
  },
  setServiceRateText(state, content) {
    state.serviceRateText = content;
  }
};

export const actions = {
  surveyData({ commit, dispatch }, surveyData) {
    commit("setState", { key: "categories", data: surveyData.categories });
    commit("setState", { key: "questions", data: surveyData.questions });
    commit("setState", { key: "answerOptions", data: surveyData.options });
    commit("setState", { key: "disabledFollowUp", data: surveyData.disabled_follow_up });
    dispatch("buildQuestions");
  },
  updateFollowUpCategories({ getters, commit }) {
    if (getters.categoryAnswers.includes(undefined)) {
      return;
    }

    const loveCategoryAnswer = sample(getters.categoryAnswers.filter(answer => answer.rating === 5));
    const lowestRating = getters.categoryAnswers.reduce(
      (rating, answer) => (answer.rating < rating ? answer.rating : rating),
      4
    );
    const improveCategoryAnswer = sample(getters.categoryAnswers.filter(answer => answer.rating === lowestRating));

    commit("setFollowUpCategory", {
      key: "loveCategory",
      data: loveCategoryAnswer && getters.questionFor(loveCategoryAnswer).question_name
    });
    commit("setFollowUpCategory", {
      key: "improveCategory",
      data: improveCategoryAnswer && getters.questionFor(improveCategoryAnswer).question_name
    });
  },
  buildQuestions({ commit, state, getters }) {
    const questions = [
      {
        content: this.$i18n.t("Please review the following items"),
        kind: "category",
        question_name: "category"
      },
      ...state.questions
    ];

    commit("setImproveCategoryText", this.$i18n.t("How can we improve the"));
    commit("setLoveCategoryText", this.$i18n.t("What did you like about the"));
    commit("setServiceRateText", this.$i18n.t("How would you rate their service?"));
    // Insert hardcoded follow up questions
    followUpQuestions.forEach(fQuestion => {
      const index = questions.findIndex(q => fQuestion.goAfter === q.question_name);
      if (index != -1 && !getters.isFollowUpDisabled(fQuestion.follow_up_name)) {
        questions.splice(index + 1, 0, fQuestion);
      }
    });
    commit("setState", { key: "nextQuestions", data: questions });
  },
  async buildFollowUpQuestion({ commit, getters }, question) {
    await commit("buildFollowUpQuestion", { question, getters });
  }
};

export const getters = {
  categories: state => state.categories,
  answers: state => state.answers,
  questions: state => state.questions,
  allQuestions: state => state.categories.concat(state.questions),
  improveCategory: state => state.improveCategory,
  language: state => state.language,
  improveCategoryText: state => state.improveCategoryText,
  loveCategory: state => state.loveCategory,
  loveCategoryText: state => state.loveCategoryText,
  serviceRateText: state => state.serviceRateText,
  currentQuestion: state => state.nextQuestions[0],
  getNextQuestions: state => state.nextQuestions,
  allCategoriesAnswered: state => state.categories.every(cat => state.answers.hasOwnProperty(cat.question_name)),
  progress: state =>
    (state.previousQuestions.length / (state.previousQuestions.length + state.nextQuestions.length)) * 100,
  optionsFor: state => question => state.answerOptions[question.id],
  questionFor: state => answer => state.categories.concat(state.questions).find(q => q.id === answer.question_id),
  answerFor: state => question => state.answers[question.question_name],
  isFollowUpDisabled: state => name => state.disabledFollowUp.includes(name),
  questionByName: (_state, getters) => questionName => getters.allQuestions.find(q => q.question_name === questionName),
  categoryAnswers: state => {
    return state.categories.filter(cat => cat.question_name != "overall").map(cat => state.answers[cat.question_name]);
  },
  currentStep: state => state.previousQuestions.length,
  initialQuestions: state => state.initialQuestions,
  skipGoogle(state) {
    return state.answers.overall && state.answers.overall.rating < 5;
  },
  shareSocialOnReload(state) {
    return state.shareSocialOnReload;
  }
};
