export const standardCategories = [
  "overall",
  "food_and_drink",
  "atmosphere",
  "service",
  "overall",
  "food_and_drink",
  "packaging",
  "value_for_money",
  "delivery_service"
];
