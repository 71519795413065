export const state = () => ({
  voucher: {},
  type: "",
  discount_code: "",
  delivery_platform_url: "",
  button_color: "",
  photo: "",
  error: undefined,
  claimed: false
});

export const getters = {
  voucher: state => state.voucher,
  claimed: state => state.voucher.claimed,
  type: state => state.type,
  discountCode: state => state.discount_code,
  deliveryPlatformUrl: state => state.delivery_platform_url,
  buttonColor: state => state.button_color,
  error: state => state.error,
  claimed(state) {
    return (state.voucher && state.voucher.claimed) || state.claimed;
  }
};

export const mutations = {
  set(state, payload) {
    state.voucher = payload.voucher;
    state.type = payload.type;
    state.discount_code = payload.discount_code;
    state.delivery_platform_url = payload.delivery_platform_url;
    state.button_color = payload.button_color;
    state.claimed = payload.claimed;
    state.error = payload.errors && payload.errors[0];
  }
};

export const actions = {
  async claimVoucher({ commit }, token) {
    try {
      const response = await this.$axios.post("/voucher/claim", { token });
      commit("set", response.data);
    } catch (err) {
      commit("set", err.response.data.errors);
    }
  },
  async checkValidity({ commit }, token) {
    try {
      const response = await this.$axios.get("/voucher/check_validity", { params: { token } });
      commit("set", response.data);
      commit("language/set", response.data.lang, { root: true });
      commit("venue/setState", { key: "venue", data: { photo: response.data.photo } }, { root: true });
    } catch (err) {
      commit("set", err.response.data.errors);
    }
  }
};
